// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>ReactApp React</div> at the bottom
// of the page.

import React from 'react'
import ReactDOM from 'react-dom/client'
import {Switch, Route} from 'react-router';
import { Provider } from "react-redux";
import store, {history} from "./stores";
import {ConnectedRouter} from "connected-react-router";
import AuthProvider from "./contexts/AuthProvider";
import AuthRoute from "./routes/AuthRoute";
import GuestRoute from "./routes/GuestRoute";
import {RootPage} from "./pages/root/RootPage";
import ProductListPage from "./pages/products/ProductListPage";
import {ExhibitsListPage} from "./pages/exhibits/ExhibitsListPage";
import {ProductPage} from "./pages/products/ProductPage";
import ExhibitPage from "./pages/exhibits/ExhibitPage";
import {CheckoutPage} from "./pages/checkout/CheckoutPage";
import CheckoutCompletePage from "./pages/checkout/CompletePage";
import InquiryPage from "./pages/inquiry/InquiryPage";
import InquiryCompletePage from "./pages/inquiry/InquiryCompletePage";
import CompanyPage from "./pages/static/CompanyPage";
import PrivacyPolicyPage from "./pages/static/PrivacyPolicyPage";
import LoginPage from "./pages/login/LoginPage";
import Password from "./pages/password";
import SignUp from "./pages/signup";
import MyPage from "./pages/mypage";
import ConfirmationsThanksPage from "./pages/confirmations/ThanksPage";
import {Layout} from "./components/layout/Layout";
import axios from "axios";
import {csrfHeader} from "./utils";
import ScrollToTop from './components/ScrollToTop'
import SyncCart from './components/cart/SyncCart'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
axios.defaults.headers.common = {...axios.defaults.headers.common, ...csrfHeader()};
Bugsnag.start({
    apiKey: 'c2c391f38e5ce19bc2b88afd2f836a12',
    plugins: [new BugsnagPluginReact()],
    endpoints: {
        notify: 'https://bugsnag.matzlika.co.jp/notify',
        sessions: 'https://bugsnag.matzlika.co.jp/sessions',
    }
});
const ErrorBoundary = Bugsnag.getPlugin('react')
    .createErrorBoundary(React)

const ReactApp = props =>
    <ErrorBoundary>
        <AuthProvider>
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <SyncCart>
                        <ScrollToTop>
                            <Layout>
                                <Switch>
                                    <Route exact path="/" component={RootPage}/>
                                    <Route path="/exhibits/:id"
                                           render={({match: {params: {id: exhibitId}}}) => <ExhibitPage exhibitId={exhibitId}/>} />
                                    <Route exact path="/shop" component={ProductListPage}/>
                                    <Route exact path="/products" component={ExhibitsListPage}/>
                                    <Route path="/products/:id"
                                           render={({match: {params: {id: productId}}}) => <ProductPage productId={productId}/>} />
                                    <Route exact path="/checkout" component={CheckoutPage}/>
                                    <Route exact path="/checkout/complete" component={CheckoutCompletePage}/>
                                    <Route exact path="/inquiry" component={InquiryPage}/>
                                    <Route exact path="/inquiry/complete" component={InquiryCompletePage}/>
                                    <Route exact path="/privacy-policy" component={PrivacyPolicyPage}/>
                                    <Route exact path="/company" component={CompanyPage}/>
                                    <GuestRoute exact path="/login" component={LoginPage} />
                                    <GuestRoute exact path="/confirmations/thanks" component={ConfirmationsThanksPage} />
                                    <GuestRoute path="/signup" component={SignUp} />
                                    <GuestRoute path="/password" component={Password} />
                                    <AuthRoute path="/mypage" component={MyPage} />
                                </Switch>
                            </Layout>
                        </ScrollToTop>
                    </SyncCart>
                </ConnectedRouter>
            </Provider>
        </AuthProvider>
    </ErrorBoundary>;

const element = document.getElementById('react-app');
if (element) {
    ReactDOM.createRoot(element)
        .render(<ReactApp name="React" />);
}
